<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>
							<span class="breadcrumbLink" @click="backPageAction()">
								<i class="el-icon-back iconBack"></i>{{$t('i18nn_ca5a01a5adf20fe7')}}</span>
						</el-breadcrumb-item>
						<el-breadcrumb-item>{{$t('i18nn_2fc1c1e30d69c8c7')}}</el-breadcrumb-item>
					</el-breadcrumb>
					<!-- <span>{{$t('i18nn_2bbc6b12fbdf9019')}}</span> -->
				</el-col>
				<el-col :span="18" class="tableConTopRig">
					<!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
		        <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">undefined</el-button> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- 修改弹窗 -->
		<!--本页切换，新增、修改、查看等-->
		<!-- <hyPageShow :visible="dialogFormVisible" @updateVisible="updateVisible" title="undefined" :subtitle="dialogFormVisibleMsg"> -->
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" :visible.sync="dialogFormVisible" :top="'10px'" custom-class="myFullDialog2"> -->
		<div class="detEditOpenCon" style="background: #fff;" v-loading="loading">

			<div>
				<el-descriptions title="">
					<el-descriptions-item :label="$t('i18nn_5e7740c52e22eefc')">{{$route.query.qaNo}}</el-descriptions-item>
					<!-- <el-descriptions-item :label="$t('i18nn_18d7892498938435')">{{baseData.cusName}}</el-descriptions-item> -->
					<el-descriptions-item :label="$t('i18nn_c944a6686d996ab3')">{{baseData.whNo}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_4dc6f04018e0f73b')">{{baseData.qaTypeName}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_7431e76029678ec7')">{{baseData.title}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_726c51702f70c486')">{{baseData.createTime}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_6cdece641436d7ab')">
						<el-tag type="warning" v-if="'10' == baseData.status">{{ $Utils.i18nKeyText(baseData,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'20' == baseData.status">
							{{ $Utils.i18nKeyText(baseData,'statusName') }}
						</el-tag>
						<el-tag type="primary" v-else-if="'30' == baseData.status">
							{{ $Utils.i18nKeyText(baseData,'statusName') }}
						</el-tag>
						<el-tag type="danger" v-else-if="'40' == baseData.status">
							{{ $Utils.i18nKeyText(baseData,'statusName') }}
						</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(baseData,'statusName') }}</el-tag>
					</el-descriptions-item>
					
					<el-descriptions-item :label="$t('i18nn_627df7b14df32cdb')">{{baseData.applyName}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_9666c59aedbfa70e')">{{baseData.qaBizTypeName}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_9168144190f66f5d')">{{baseData.relationNo}}</el-descriptions-item>
					<!-- <el-descriptions-item label="关联ID" v-if="baseData.relationId">{{baseData.relationId}}</el-descriptions-item> -->
					
					<el-descriptions-item :label="$t('i18nn_15b3627faddccb1d')"><div class="pre-text">{{baseData.remark}}</div></el-descriptions-item>
					
				</el-descriptions>
			</div>

			<div>

				<el-card header="" shadow="never" v-loading="loading_det">
					<div slot="header">
						<h3>{{$t('i18nn_2fc1c1e30d69c8c7')}}</h3>
					</div>

					<div>
						<div style="margin-bottom: 10px;">
							<el-button type="primary" icon="el-icon-plus" @click="addProgress()">{{$t('i18nn_3c7beea052b8230c')}}</el-button>
						</div>

						<el-timeline>
							<el-timeline-item :timestamp="item.createTime" placement="top"
								v-for="(item,index) in progressList" :key="index">
								<el-card shadow="never" class="timeCard" :class="{'timeCard_SYS':'SYS'==item.client,'timeCard_BIZ':'BIZ'==item.client}">
									<div class="timeCardTag">
										<el-tag type="success" effect="dark" size="small" v-if="'SYS'==item.client">{{$t('i18nn_05682dbef5eeb0a8')}}</el-tag>
										<el-tag type="warning" effect="dark" size="small" v-else-if="'BIZ'==item.client">{{$t('i18nn_18d7892498938435')}}</el-tag>
									</div>
									<div class="pre-text">{{item.qaText}}</div>
									<div v-if="item.qaAlikey">
										<el-button type="text" icon="el-icon-right" @click="openMoreText(item.qaAlikey)">{{$t('i18nn_354482714c59064a')}}</el-button>
									</div>
									<div v-if="item.attachments && item.attachments.length>0">
										<h3>{{$t('i18nn_f5d43732e3f6cf4d')}}</h3>
										<ul>
											<li v-for="(item2,index2) in item.attachments" :key="index2">
												<el-image style="width: 50px; height: 50px;vertical-align: middle;"
													:z-index="9999" :fit="'contain'" :src="item2.url"
													:preview-src-list="item.attachments.map(itemPre=> { return itemPre.url})">
													<div slot="error" class="image-slot">
													  <i class="el-icon-document"></i>
													</div>
												</el-image>
												<a :href="item2.url" :title="item2.url"
													target="_blank">{{ item2.fileName }}</a>
											</li>
										</ul>
									</div>
									<!-- <div v-html="item.qaMoreText"></div> -->
								</el-card>
							</el-timeline-item>
						</el-timeline>
					</div>

				</el-card>
			</div>

		</div>

		<!-- 新增 -->
		<QARecProgressAdd :openTime="QARecProgressAddOpenTime" :row="QARecProgressAddOpenRow"
			@SaveSuccessBack="initData()"></QARecProgressAdd>
	
		<!-- -->
		<QARecProgressViewMoreText ref="QARecProgressViewMoreText" :openTime="ViewMoreTextOpenTime" :row="ViewMoreTextRow"></QARecProgressViewMoreText>
			

	</div>
</template>
<script>
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import dialogFileUpload from '@/components/WarehouseCenter2/components/dialogFileUpload.vue';
	
	import QARecProgressViewMoreText from '@/components/StorageCenter/QA/QARecProgressViewMoreText.vue';
	
	import QARecProgressAdd from '@/components/StorageCenter/QA/QARecProgressAdd.vue';

	export default {
		components: {
			QARecProgressAdd,
			QARecProgressViewMoreText
		},
		data() {
			return {
				// cusUserId: '',

				// pageTitle: this.$t('i18nn_e8564657bbe9ca53'),
				
				ViewMoreTextOpenTime:"",
				ViewMoreTextRow:{},
				// FileUploadOpenTime:'',
				// FileUploadOpenIndex:'',
				// // cusUserName: '',
				// //打开选择框
				// dialogSelVisible: false,

				// dialogAddVisible: false,
				// selBillData: {},
				// // openRowData: {},
				// // openRowIndex: {},

				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				// dialogFormInvalidDate: false,
				// currentSel: {},
				// multipleSelection: [],

				// pickerOptions: {
				// 	// disabledDate(time) {
				// 	//   return time.getTime() >= Date.now();
				// 	// },
				// 	shortcuts: [
				// 		{
				// 			text: 'Latest Week',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Month',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Three M',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		}
				// 	]
				// },
				// selectOption: {
				// 	wh_no: [],
				// 	wh_qa_type:[],
				// 	// wh_op_status: [],
				// 	// wh_fin_bill_status: [],
				// 	// wh_add_sf_type: [],
				// 	// wh_add_sf_price_type: [],
				// 	// // wh_op_is_bill:[],
				// 	// plStatusList: [
				// 	// 	{
				// 	// 		value: '',
				// 	// 		label: this.$t('i18nn_16853bda54120bf1')
				// 	// 	},
				// 	// 	{
				// 	// 		value: '1',
				// 	// 		label: this.$t('i18nn_9cee201b82dbc9cb')
				// 	// 	},
				// 	// 	{
				// 	// 		value: '2',
				// 	// 		label: this.$t('i18nn_1ff1fd8ddb3e0e8d')
				// 	// 	}
				// 	// ]
				// },

				//表格数据
				//loading,表格数据
				loading: false,
				// loading_load: false,
				//表格数据
				// tableData: [],
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30],
				// 	page_size: 20,
				// 	total: 0
				// },
				//详情数据
				loading_det: false,

				// leaveMessage:"",

				baseData: {},
				progressList: [],

				QARecProgressAddOpenTime: "",
				QARecProgressAddOpenRow: {},
				// form: {
				// 	"title": null, //this.$t('i18nn_7431e76029678ec7'),
				// 	"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
				// 	"cusId": null,
				// 	"qaType": null, //问题类型
				// 	"remark": null, //this.$t('i18nn_15b3627faddccb1d'),
				// 	"qaProgress": {
				// 		"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
				// 		"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
				// 	}
				// },

				// formRules: {
				// 	title: [{
				// 		required: true,
				// 		message: this.$t('i18nn_5a9aefbc03c778f7'),
				// 		trigger: 'blur'
				// 	}],
				// 	remark: [{
				// 		required: true,
				// 		message: this.$t('i18nn_5a9aefbc03c778f7'),
				// 		trigger: 'blur'
				// 	}],
				// 	whNo: [{
				// 		required: true,
				// 		message: this.$t('i18nn_2ad108ab2c560530')
				// 	}],
				// 	userId: [{
				// 		required: true,
				// 		message: this.$t('i18nn_2ad108ab2c560530')
				// 	}]
				// },
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// workNo: '', //this.$t('i18nn_daf169d1f7dceda0'),
					whNo: '', //this.$t('i18nn_5e7740c52e22eefc'),
					// wh_op_is_bill:'',
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					// daterange: [],
					// wh_op_status: '',
					// wh_fin_bill_status: '',
					// plStatus:'',
					// opNo: ''
					// plName: ''
				}
			};
		},
		// watch: {
		//   $route(to, from) {
		// 		console.log('watch');
		//     this.initData();
		//   }
		// },
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {
			// console.log("mounted");
			// this.getWhNoData();
			// this.getDicData();
		},
		methods: {
			initData() {
				// this.resetForm('form');

				// if (this.$route.query.id) {
				// this.pageTitle = this.$t('i18nn_6267f3aedf895209');
				this.baseData = {};
				this.progressList = [];
				
				this.getBaseData(this.$route.query.qaNo);
				this.getDetData(this.$route.query.id);
				// }
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				// if (!this.cusUserId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				// this.getPageData();
				// this.getDicData();
			},
			
			backPageAction() {
				if(window.history.length>2){
					this.$router.go(-1);
				} else {
					this.$router.push({
						name: 'QARecList',
						query: {}
					});
				}
			},

			addProgress() {
				// event.stopPropagation();
				this.QARecProgressAddOpenRow = {
					id: this.$route.query.id
				};
				this.QARecProgressAddOpenTime = new Date().getTime();
			},
			//打开留言
			// openLeaveMessage(row){
			// 	// event.stopPropagation();
			// 	this.LeaveMessageOpenRow = row;
			// 	this.LeaveMessageOpenTime = new Date().getTime();
			// },
			openMoreText(id){
			// 	// event.stopPropagation();
				this.ViewMoreTextRow = {id:id};
				this.ViewMoreTextOpenTime = new Date().getTime();
				
				// ViewMoreTextOpenTime:"",
				// ViewMoreTextRow:{},
			},
			//附件上传成功回调
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);

			// 	// this.form. = 
			// 	let item = this.form.finRecords[this.FileUploadOpenIndex];

			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath //this.$t('i18nn_8758fd50c87d6c9c')
			// 		}
			// 	});
			// 	if (!item.fileList) {
			// 		item.fileList = [];
			// 	}
			// 	item.fileList = item.fileList.concat(fileList);

			// 	this.$set(this.form.finRecords, this.FileUploadOpenIndex, item);
			// 	// this.form.evidenceList = data.map(item=>{
			// 	// 	return {
			// 	// 		fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 	// 		url: item.filePath//this.$t('i18nn_8758fd50c87d6c9c')
			// 	// 	}
			// 	// });

			// 	// this.submitForm('form');

			// 	// this.initData();
			// 	// event.stopPropagation();
			// 	// this.isShowFileUpload = true;
			// 	// this.fileRelationId = row.id;
			// 	// this.FileUploadOpenTime = new Date().getTime();
			// },

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.cusUserId = data.userId;
			// 	// this.cusUserName = data.companyName;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },

			// changCus2(data) {
			// 	console.log('changCus', data);

			// 	// this.form.cusName = data.companyName;

			// 	// this.form.cusNo = data.cusNo;
			// 	this.form.cusId = data.userId;
			// },
			//计算费用明细的金额
			// changeFinAmt(item,index){
			// 	// let item = this.form.finRecords[0];
			// 	item.finAmt = (parseFloat(item.quantity?item.quantity:0)*parseFloat(item.price?item.price:0)).toFixed(2);
			// 	this.$set(this.form.finRecords,index,item);
			// },
			//添加附件
			// openAddFile(index){
			// 	this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			//新增
			// addLe2() {
			// 	this.form.finRecords.push({
			// 		finName: '',
			// 		finAmt: '0',
			// 		opTime: this.$Utils.fomatterDate_YYYYMMdd(new Date()),
			// 		addSfType: '',
			// 		addPriceType: '',
			// 		quantity : '0',
			// 		price : '0',
			// 		fileList: [],
			// 		remark: ''
			// 	});
			// },
			//删除
			// delLe2(index) {
			// 	this.form.finRecords.splice(index, 1);
			// },
			// //删除附件
			// delFile(index,index2,item) {

			// 	if(item.id && item.userId){
			// 		this.delData(this.$urlConfig.WhFileUploadDel+'/'+item.id+'/'+item.userId, {}, data => {
			// 			console.log('delData', data);
			// 			this.$message.success('删除成功！');
			// 			// this.form.finRecords[index].fileList.splice(index2, 1);
			// 			let item = this.form.finRecords[index];
			// 			item.fileList.splice(index2, 1);
			// 			this.$set(this.form.finRecords,index,item);
			// 		});
			// 	} else {
			// 		// this.form.finRecords[index].fileList.splice(index2, 1);
			// 		let item = this.form.finRecords[index];
			// 		item.fileList.splice(index2, 1);
			// 		this.$set(this.form.finRecords,index,item);
			// 	}



			// },
			//弹窗更新
			// updateVisible(val) {
			// 	this.dialogFormVisible = val;
			// },
			// openDioalog(formParm, msg) {
			// 	console.log(formParm);
			// 	this.dialogFormVisibleMsg = msg;
			// 	this.dialogFormVisible = true;
			// 	// let form = Object.assign({}, formParm);
			// 	// console.log(form);
			// 	// 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增
			// 		// 重置
			// 		// this.resetForm('form');
			// 	} else {
			// 		//修改
			// 		// 重置
			// 		// this.resetForm('form');
			// 		let form = Object.assign({}, formParm);
			// 		this.form = Object.assign({}, form);
			// 		this.getDetListData(form.opNo);
			// 	}
			// },
			//打开编辑
			// openEdit(event, row) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'));
			// },

			// 富文本
			// onEditorChange(html) {
			// 	// this.form.contentStr = html;
			// 	this.form.qaMoreText = html;
			// },

			// resetForm(formName) {
			// 	console.log(formName);

			// 	this.form = {
			// 		"title": null, //this.$t('i18nn_7431e76029678ec7'),
			// 		"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
			// 		"qaType": null, //问题类型
			// 		"cusId": null,
			// 		"remark": null, //this.$t('i18nn_15b3627faddccb1d'),
			// 		"qaProgress": {
			// 			"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
			// 			"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
			// 		}
			// 	}

			// 	this.$nextTick(() => {
			// 		this.$refs.whNoSelect.init();
			// 		this.$refs.cusSelFuzzy.init();
			// 	});
			// 	// this[formName] = {};

			// 	// if ('form' === formName) {
			// 	// 	this[formName]['finRecords'] = [{
			// 	// 			finName: '', //this.$t('i18nn_a4eca6ff2588d791'),
			// 	// 			finAmt: '' ,//"0",
			// 	// 			opTime:this.$Utils.fomatterDate_YYYYMMdd(new Date()),
			// 	// 			addSfType:'',
			// 	// 			addPriceType:'',
			// 	// 			// "id":"",//"数据ID"
			// 	// 		}];
			// 	// }
			// 	// if (this.$refs[formName]) {
			// 	// 	this.$refs[formName].resetFields();
			// 	// 	// this.form.remark = ""; //  备注//非必填
			// 	// } else {
			// 	// 	console.log('this.$refs[formName]', this.$refs[formName]);
			// 	// }
			// },

			//提交信息
			// submitForm(formName, type) {
			// 	if (!this.form.cusId) {
			// 		this.$alert(this.$t('i18nn_5184f7a4033b4969'), this.$t('i18nn_cc62f4bf31d661e3'), {
			// 			type: 'warning'
			// 		});
			// 		return;
			// 	}
			// 	if (!this.form.whNo) {
			// 		this.$alert(this.$t('FormMsg.Select_long_wh'), this.$t('i18nn_cc62f4bf31d661e3'), {
			// 			type: 'warning'
			// 		});
			// 		return;
			// 	}
			// 	//验证
			// 	this.$refs[formName].validate(valid => {
			// 		// if (true) {
			// 		if (valid) {
			// 			// alert('submit!');

			// 			//浅拷贝、对象属性的合并
			// 			// this.form = Object.assign({},form);
			// 			// if ('1' === type) {

			// 			//新增
			// 			let formData = Object.assign({}, this.form);
			// 			// formData.userId = null;
			// 			// formData.id = null;
			// 			// formData.bizType = '10';
			// 			this.postData(this.$urlConfig.WhQARecAdd + "/" + formData.cusId, formData, data => {
			// 				console.log('postData', data);
			// 				// this.dialogFormVisible = false;
			// 				// this.getPageData();
			// 				// this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				//   type: 'success',
			// 				//
			// 				// });
			// 				this.$message.success('保存成功！');
			// 				// if (data && data.userId) {
			// 				// 	this.backPageAction(data.userId);
			// 				// } else {
			// 				this.backPageAction();
			// 				// }
			// 			});
			// 			// } else if ('2' === type) {
			// 			// 	//注销
			// 			// 	// let formData = Object.assign({}, this.invalidDateForm);
			// 			// 	// formData.id = this.currentSel.id;
			// 			// 	// this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
			// 			// } else if ('3' === type) {
			// 			// 	//编辑
			// 			// 	// let formData = Object.assign({}, this.form);
			// 			// 	// // formData.id = null;
			// 			// 	// this.postData(this.$urlConfig.WhFinanceCreate, formData, () => {
			// 			// 	// 	this.dialogFormVisible = false;
			// 			// 	// 	this.getPageData();
			// 			// 	// 	// this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 			// 	// 	//   type: 'success',
			// 			// 	// 	//
			// 			// 	// 	// });
			// 			// 	// 	this.$message.success('编辑成功！');
			// 			// 	// });
			// 			// }
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert('提交有误,请检查提交数据！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data.data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//提交数据
			// delData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.delete(url, formData)
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data.data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//基础信息
			getBaseData(qaNo) {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhQARecPageList, {
						limit: 10,
						offset: 0,
						qaNo: qaNo
					})
					.then(({
						data
					}) => {
						
						console.log('data', data);
						this.loading = false;
						if (200 == data.code && data.rows && data.rows[0]) {
							this.baseData = data.rows[0];
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading = false;
					});
			},

			//编辑详情的数据
			getDetData(id) {
				this.loading_det = true;
				this.$http
					.get(this.$urlConfig.WhQARecProgressGet + '/' + id, {})
					.then(({
						data
					}) => {
						
						console.log('data', data);
						this.loading_det = false;
						if (200 == data.code && data.rows) {

							this.progressList = data.rows;
							//表格显示数据
							// this.form = data.data;

							// this.$nextTick(() => {
							// 	this.$refs.whNoSelect.init(data.data.whNo);
							// 	this.$refs.cusSelFuzzy.init(data.data.userId);
							// });

							// this.tableData = data.rows;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_det = false;
					});
			},
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.errorData'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_qa_type'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_qa_type = data.data['wh_qa_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.timeCard {
		position: relative;
		padding-top: 10px;
		.timeCardTag {
			position: absolute;
			top: 0;
			left:0;
		}
	}
	.timeCard_SYS {
		border-color:#67c23a;
	}
	.timeCard_BIZ {
		border-color:#e6a23c;
	}
</style>
